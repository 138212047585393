<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button type="danger" @click="handleDelete">
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <section class="add-module">
        <!-- 分组表单信息 -->
        <m-form
          ref="myForm"
          :form-data="containerFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        >
          <!-- 上传图片 -->
          <template #appendComponent>
            <upload-img :disabled="mode === 'detail'" />
          </template>
        </m-form>
        <!-- 面 按钮组 -->
        <floor-info
          v-if="isShowFloor"
          :key="1"
          :container-direction="formModel.containerType.containerDirection"
          :table-item="tableFormItem"
          :table-data="formModel.containerTypeFloorList"
          :mode="mode"
          radio-name="floor"
          @changeDirection="handleChangeFloorDirection"
        />
      </section>
      <section
        v-if="formModel.containerTypeGoodsPositionList.length"
        class="add-module"
        style="margin-top: 10px"
      >
        <h3 class="title">{{ $t('lang.gles.containerType.goodsPositionInfo') }}</h3>
        <floor-info
          v-if="isShowFloor"
          :key="2"
          :container-direction="formModel.containerType.containerDirection"
          :table-item="goodsPositionTableFormItem"
          :table-data="formModel.containerTypeGoodsPositionList"
          :mode="mode"
          radio-name="goodsPosition"
        />
      </section>
    </div>
  </el-drawer>
</template>
<script>
import UploadImg from '@/components/upload/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import {
  getEditBaseFormData,
  getTableFormItem,
  getGoodsPositionFormItem
} from '../data'
import { mapState } from 'vuex'
import FloorInfo from './floorInfo.vue'

export default {
  name: 'ContainerTypeDetail',
  components: {
    UploadImg,
    MForm,
    FloorInfo
  },
  mixins: [addMixins, commonMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    /**
     * 可放子容器 默认是
     */
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      extendFormConfig: {
        isNeedBtn: false,
        isGroup: true
      },
      currentDirection: 'F',
      goodsPositionTableFormData: [],
      formModel: {
        containerType: {
          containerDirection: 'F',
          floorNum: 1
        },
        containerTypeFloorList: [],
        containerTypeGoodsPositionList: []
      },
      containerTypeList: []
    }
  },
  computed: {
    ...mapState('base', [
      'flagList',
      'directionList',
      'containerTypePatternList',
      'containerTypePatternTypeList',
      'containerDirectionList'
    ]),
    containerFormData() {
      const row = this.rowDetail
      return getEditBaseFormData(this, { ...row })
    },
    tableFormItem() {
      return getTableFormItem(this, { ...this.rowDetail })
    },
    goodsPositionTableFormItem() {
      const row = this.rowDetail || {}
      return getGoodsPositionFormItem(this, { ...row })
    },
    /**
     * 是否显示楼层规格信息
     */
    isShowFloor() {
      // return !['周转箱', '料筒'].includes(this.formModel.containerTypePattern)
      return !['containerPatternCrate', 'containerPatternBarrel'].includes(
        this?.formModel?.containerType?.containerTypePattern
      )
    },
    childContainerTypeList() {
      let ctypes = []
      const { childContainerFlag, containerTypePattern } =
        this.formModel.containerType
      // 可选子容器为 否
      if (childContainerFlag) {
        return []
      }
      switch (containerTypePattern) {
        case 'containerPatternFixedShelf': // 固定货架
        case 'containerPatternStackingShelves': // 地堆货架
          // ['托盘', '周转箱', '料筒']
          ctypes = [
            'containerPatternTray',
            'containerPatternCrate',
            'containerPatternBarrel'
          ]
          break
        case 'containerPatternTray': // 托盘
        case 'containerPatternShelf': // 货架
        case 'containerPatternRobotComponents': // 机器人组件
          // ['周转箱', '料筒']
          ctypes = ['containerPatternCrate', 'containerPatternBarrel']
          break
        default:
          break
      }
      return this.containerTypeList.filter((item) =>
        ctypes.includes(item.containerTypePattern)
      )
    }
  },
  created() {
    this.initSelectList()
    this.getDetail()
  },
  methods: {
    initSelectList() {
      this.getContainerTypeList()
      if (!this.getContainerTypePatternList?.length) {
        this.$store.dispatch('base/getContainerTypePatternList')
      }
      if (!this.containerTypePatternTypeList?.length) {
        this.$store.dispatch('base/getContainerTypePatternTypeList')
      }
      if (!this.containerDirectionList?.length) {
        this.$store.dispatch('base/getContainerDirectionList')
      }
    },
    async getDetail() {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryContainerTypeDetail'),
        { id: this.rowDetail.id }
      )
      if (code) return
      const {
        containerType = {},
        containerTypeFloorList = [],
        containerTypeGoodsPositionList = []
      } = data || {}

      this.echoChildContainerType(containerTypeFloorList)
      this.echoChildContainerType(containerTypeGoodsPositionList)
      containerTypeFloorList?.forEach((item) => {
        item.materialMaximum = item.materialMaximum ?? undefined
        item.containerMaximum = item.containerMaximum ?? undefined
      })
      containerTypeGoodsPositionList?.forEach((item) => {
        item.containerDirectionName = this.$t(
          this.containerDirectionList?.find(
            (t) => t.value === item.containerDirection
          )?.label ?? ''
        )
        item.materialMaximum = item.materialMaximum ?? undefined
        item.containerMaximum = item.containerMaximum ?? undefined
      })
      this.formModel = {
        containerType,
        containerTypeFloorList: containerTypeFloorList || [],
        containerTypeGoodsPositionList: containerTypeGoodsPositionList || []
      }
    },
    /**
     * 回显子容器类型
     * @param {*} list
     */
    echoChildContainerType(list = []) {
      list?.forEach((item) => {
        item.childContainerTypeList =
          item.childContainerType
            ?.split(';')
            ?.map((t) => parseInt(t))
            ?.filter((t) => !!t) ?? []
      })
    },
    handleChangeFloorDirection() {},
    /**
     * 编辑
     * 编辑失败，该容器类型存在可用容器
     */
    async handleEdit() {
      const deleteFlag = await this.deleteContainerTypeAssert(this.rowDetail)
      if (deleteFlag) {
        this.$message.error(this.$t('lang.gles.containerType.checkMessage1'))
        return
      }
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'ContainerTypeAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     */
    async handleDelete() {
      // 二次弹框
      const confirmMsg = this.$t('lang.gles.common.deleteConfirmMsg0')
      this.$confirm(confirmMsg, '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteContainerType()
      })
    },
    /**
     * 接口删除容器类型
     * 1、先断言，判断是否存在关联容器档案
     * 2、无关联容器档案可删除
     */
    async deleteContainerType() {
      const deleteFlag = await this.deleteContainerTypeAssert(this.rowDetail)
      if (deleteFlag) {
        this.$message.error(this.$t('lang.gles.containerType.checkMessage1'))
        return
      }
      const {
        containerType,
        containerTypeFloorList,
        containerTypeGoodsPositionList
      } = this.formModel
      const containerFloorIds = containerTypeFloorList.map((item) => item.id)
      const containerGoodsPositionIds = containerTypeGoodsPositionList.map(
        (item) => item.id
      )
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteContainerType'),
        {
          containerId: containerType.id,
          containerFloorIds,
          containerGoodsPositionIds
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.$emit('update:visible', false)
      this.$parent.doSearch(this.formModel)
    },
    /**
     * 删除断言，判断是否可编辑、可删除
     * 该容器类型下无容器档案记录，则可编辑
     * 1、删除失败，该容器类型存在可用容器
     * 2、编辑失败，该容器类型存在可用容器
     */
    async deleteContainerTypeAssert(row) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('deleteContainerTypeAssert'),
        { containerId: row.id }
      )
      if (code) return
      return data
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
