=
<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.containerTypeCode }}
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import DetailDialog from './detail'

export default {
  name: 'ContainerList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      detailDialogVisible: false,
      mode: '',
      baseFormModel: {
        codeOrName: ''
      },
      containerTypeList: []
    }
  },
  computed: {
    ...mapState('base', [
      'flagList',
      'containerTypePatternList',
      'containerTypePatternTypeList'
    ]),
    moreQueryData() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    await this.initSelectList()
    this.doSearch(this.baseFormModel)
  },
  methods: {
    initSelectList() {
      if (!this.getContainerTypePatternList?.length) {
        this.$store.dispatch('base/getContainerTypePatternList')
      }
      if (!this.containerTypePatternTypeList?.length) {
        this.$store.dispatch('base/getContainerTypePatternTypeList')
      }
      this.getContainerTypeList()
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset(data) {
      this.formModel = {
        ...data,
        childContainerFlag: 0
      }
    },
    // 新增
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'ContainerTypeAdd',
        mode: 'add'
      })
    },

    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getContainerTypeList')
    },
    // 查询
    async doSearch(params, api = 'getContainerTypeListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.base(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        console.log(error)
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    /**
     * 编辑
     */
    async editItem({ row }) {
      const deleteFlag = await this.deleteContainerTypeAssert(row)
      if (deleteFlag) {
        this.$message.error(this.$t('lang.gles.containerType.checkMessage1'))
        return
      }
      this.$emit('updateCom', {
        currentCom: 'ContainerTypeAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除
     */
    async delItem({ row }) {
      const deleteFlag = await this.deleteContainerTypeAssert(row)
      if (deleteFlag) {
        this.$message.error(this.$t('lang.gles.containerType.checkMessage0'))
        return
      }
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteContainerTypeById'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.formModel)
    },
    /**
     * 删除断言，判断是否可编辑、可删除
     * 该容器类型下无容器档案记录，则可编辑
     * 1、删除失败，该容器类型存在可用容器
     * 2、编辑失败，该容器类型存在可用容器
     */
    async deleteContainerTypeAssert(row) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('deleteContainerTypeAssert'),
        { containerId: row.id }
      )
      if (code) return
      return data
    }
  }
}
</script>
