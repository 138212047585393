<template>
  <div>
    <el-radio-group
      :key="radioName"
      v-model="currentDirection"
      class="gles-radio-group"
      @change="handleRadioDirection"
    >
      <el-radio-button
        v-for="(side, i) in radioDirectionList"
        :key="`${i}${radioName}`"
        :label="side.value"
      >
        {{ side.label }}
      </el-radio-button>
    </el-radio-group>
    <!-- 表格 表单 -->
    <table-form
      :table-item="tableItem"
      :table-data.sync="innerTableData"
      :disabled="mode === 'detail'"
      :extend-config="extendConfig"
      size="mini"
      @update:table-data="updateTableData"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TableForm from '@/components/tableForm/index.vue'
export default {
  components: {
    TableForm
  },
  props: {
    type: {
      type: String,
      default: 'floor'
    },
    radioName: {
      type: String,
      default: 'floor'
    },
    show: {
      type: Boolean,
      default: false
    },
    // 容器面（单面/双面/四面）
    containerDirection: {
      type: String,
      default: 'F'
    },
    tableItem: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    // 新增/编辑/详情
    mode: {
      type: String,
      default: 'add'
    },
    /**
     * tableForm组件的引用
     */
    tableRef: {
      type: String,
      default: 'floorTableForm'
    }
  },
  data() {
    return {
      currentDirection: 'F',
      innerTableData: [],
      otherDirectionData: [],
      extendConfig: {},
      containerTypeList: []
    }
  },
  computed: {
    ...mapState('base', ['containerDirectionList']),
    /**
     * 面 单选按钮组
     */
    radioDirectionList() {
      return this.containerDirectionList.filter((item) =>
        this.containerDirection?.includes(item.value)
      )
    }
  },
  watch: {
    tableData: {
      handler(val, oldVal) {
        if (!val) return
        this.setTableData()
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    /**
     * 面按钮 事件处理
     */
    async handleRadioDirection() {
      this.$emit('update:currentDirection', this.currentDirection)
      this.setTableData()
      if (this.mode === 'add') {
        return
      }
    },
    setTableData() {
      this.innerTableData = this.tableData?.filter(
        (item) => item.containerDirection === this.currentDirection
      )
      this.otherDirectionData = this.tableData?.filter(
        (item) => item.containerDirection !== this.currentDirection
      )
    },
    /**
     * 更新表格信息
     */
    updateTableData() {
      this.$emit('update:tableData', [
        ...this.otherDirectionData,
        ...this.innerTableData
      ])
    }
  }
}
</script>
