<template>
  <div class="add-ui">
    <el-card class="add-card container-type-add-card">
      <el-steps :active="active" class="add-steps" align-center>
        <el-step :title="$t('lang.gles.containerType.containerInfo')" />
        <el-step :title="$t('lang.gles.containerType.goodsPositionInfo')" />
      </el-steps>
      <div class="content-wrapper">
        <section v-if="addModule === 'container'" class="add-module">
          <!-- 分组表单信息 -->
          <m-form
            ref="myForm"
            :form-data="containerBaseFormData"
            label-position="right"
            :label-width="120"
            :extend-config="extendFormConfig"
          >
            <!-- 上传图片 -->
            <template #appendComponent>
              <upload-img />
            </template>
          </m-form>
          <!-- 规格 表格信息 -->
          <floor-info
            v-if="isShowFloor"
            key="containerTypeFloor"
            ref="floorTableForm"
            type="floor"
            table-ref="floorTableForm"
            :container-direction="formModel.containerType.containerDirection"
            :table-item="tableFormItem"
            :table-data.sync="formModel.containerTypeFloorList"
            :mode="mode"
          />
        </section>
        <section v-if="addModule === 'goodsPosition'" class="add-module">
          <h3 class="title" style="margin-bottom: 10px">
            {{ $t('lang.gles.containerType.goodsPositionInfo') }}
          </h3>
          <floor-info
            key="containerTypeGoodsPosition"
            ref="positionTableForm"
            type="goodsPosition"
            table-ref="positionTableForm"
            :container-direction="formModel.containerType.containerDirection"
            :table-item="goodsPositionTableFormItem"
            :table-data.sync="formModel.containerTypeGoodsPositionList"
            :mode="mode"
          />
        </section>
      </div>
      <div class="footer-btns">
        <div class="btns">
          <el-button
            :type="addModule === 'container' ? 'info' : 'primary'"
            @click="prevStep"
          >
            {{ $t('lang.gles.common.prevStep') }}
          </el-button>
          <el-button v-debounce="nextStep" type="primary">
            {{
              addModule === 'container'
                ? $t('lang.gles.common.nextStep')
                : $t('lang.gles.common.save')
            }}
          </el-button>
          <el-button @click="onCancel">
            {{ $t('lang.gles.common.cancel') }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import UploadImg from '@/components/upload/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import FloorInfo from './floorInfo.vue'

import {
  getEditBaseFormData,
  getTableFormItem,
  getGoodsPositionFormItem
} from '../data'
import { mapState } from 'vuex'

export default {
  name: 'AddContainerType',
  components: {
    UploadImg,
    MForm,
    FloorInfo
  },
  mixins: [addMixins, commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    /**
     * 可放子容器 默认是
     */
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: 1,
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: true,
        isGroup: true
      },
      addModule: 'container',
      currentDirection: 'F',
      goodsPositionCurrentDirection: 'F',
      goodsPositionTableFormData: [],
      emptyTableFormData: {
        floorNum: 1,
        columnNum: 1,
        rowNum: 1,
        floorHigh: null,
        childContainerType: null,
        containerMaximum: undefined,
        materialMaximum: undefined,
        containerTypeCode: null,
        containerDirection: 'F',
        deleteFlag: 0
      },
      floors: {},
      formModel: {
        containerType: {
          containerDirection: 'F',
          floorNum: 1,
          childContainerFlag: 0
        },
        containerTypeFloorList: [],
        containerTypeGoodsPositionList: []
      },
      isSimpleModel: false,
      containerTypeList: []
    }
  },
  computed: {
    ...mapState('base', [
      'flagList',
      'directionList',
      'containerTypePatternList',
      'containerTypePatternTypeList',
      'containerDirectionList',
      'floorNumList'
    ]),
    /**
     * 基本信息
     */
    containerBaseFormData() {
      const row = { ...this.formModel.containerType }
      return getEditBaseFormData(this, row)
    },
    /**
     * 规格信息，表格列表项
     */
    tableFormItem() {
      return getTableFormItem(this, { ...this.formModel.containerType })
    },
    /**
     * 货位信息 表格列表项
     */
    goodsPositionTableFormItem() {
      const row = this.rowDetail || {}
      return getGoodsPositionFormItem(this, { ...row })
    },
    /**
     * 可选子容器类型
     *  1、若容器类型形态是 固定货架/地堆货架：加载所有形态是托盘、周转箱、料筒 容器类型编码列表。
     *  2、若容器类型形态是 托盘/货架/机器人组件：加载所有形态是周转箱、料筒 容器类型编码列表。
     *  3、支持多选
     */
    childContainerTypeList() {
      let ctypes = []
      const { childContainerFlag, containerTypePatternType } =
        this.formModel.containerType
      // 可选子容器为 否
      if (childContainerFlag) {
        return []
      }
      switch (containerTypePatternType) {
        case 'fixedContainer':
          // ['托盘', '周转箱', '料筒']
          ctypes = [
            'containerPatternTray',
            'containerPatternCrate',
            'containerPatternBarrel'
          ]
          break
        case 'moveContainer':
          ctypes = ['containerPatternCrate', 'containerPatternBarrel']
          break
        default:
          break
      }
      return this.containerTypeList.filter((item) =>
        ctypes.includes(item.containerTypePattern)
      )
    },
    containerTypePatternType() {
      // 固定容器 固定货架/地堆货架
      const fixedContainer = [
        'containerPatternFixedShelf',
        'containerPatternStackingShelves'
      ]
      // 移动容器 托盘/货架/机器人组件
      const moveContainer = [
        'containerPatternTray',
        'containerPatternShelf',
        'containerPatternRobotComponents'
      ]
      // 周转容器 周转箱/料桶
      // const turnoverContainer = ['containerPatternCrate', 'containerPatternBarrel']
      const { containerTypePattern } = this.formModel?.containerType || {}
      if (fixedContainer.includes(containerTypePattern)) {
        return 'fixedContainer'
      } else if (moveContainer.includes(containerTypePattern)) {
        return 'moveContainer'
      } else {
        return 'turnoverContainer'
      }
    },
    /**
     * 是否显示楼层规格信息
     */
    isShowFloor() {
      return this.containerTypePatternType !== 'turnoverContainer'
    }
  },
  watch: {
    'formModel.containerType.containerDirection': {
      handler(val, oldVal) {
        this.setFloors(val)
      },
      immediate: true
    },
    containerBaseFormData() {
      this.$nextTick(() => {
        this.$refs.myForm?.changeFormValue(this.formModel.containerType ?? {})
      })
    }
  },
  async created() {
    if (this.mode === 'add') {
      const containerTypeCode = await this.getSerialBizCode(
        'containerTypeSerialNum',
        'containerTypeCode'
      )
      this.$set(
        this.formModel.containerType,
        'containerTypeCode',
        containerTypeCode
      )
      this.formModel.containerType.containerTypeCode = containerTypeCode
    }
    this.initSelectList()
    if (this.mode === 'edit') {
      this.getDetail()
    }
  },
  methods: {
    initSelectList() {
      if (!this.containerTypePatternList?.length) {
        this.$store.dispatch('base/getContainerTypePatternList')
      }
      if (!this.containerTypePatternTypeList?.length) {
        this.$store.dispatch('base/getContainerTypePatternTypeList')
      }
      if (!this.containerDirectionList?.length) {
        this.$store.dispatch('base/getContainerDirectionList')
      }
      this.getContainerTypeList()
    },
    async getDetail() {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryContainerTypeDetail'),
        { id: this.rowDetail.id }
      )
      if (code) return
      const {
        containerType = {},
        containerTypeFloorList = [],
        containerTypeGoodsPositionList = []
      } = data || {}
      this.echoChildContainerType(containerTypeFloorList)
      this.echoChildContainerType(containerTypeGoodsPositionList)
      this.formModel = {
        containerType,
        containerTypeFloorList: containerTypeFloorList || [],
        containerTypeGoodsPositionList: containerTypeGoodsPositionList || []
      }
      this.formModel.containerTypeGoodsPositionList.forEach((item) => {
        item.containerDirectionName = this.$t(
          this.containerDirectionList?.find(
            (t) => t.value === item.containerDirection
          )?.label ?? ''
        )
        item.materialMaximum = item.materialMaximum ?? undefined
        item.containerMaximum = item.containerMaximum ?? undefined
      })
    },
    /**
     * 回显子容器类型
     * @param {*} list
     */
    echoChildContainerType(list = []) {
      list?.forEach((item) => {
        item.childContainerTypeList =
          item.childContainerType
            ?.split(';')
            ?.map((t) => parseInt(t))
            ?.filter((t) => !!t) ?? []
      })
    },
    /**
     * 表单项改变事件
     */
    onContainerFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel.containerType, formItem.name, val)
      this.$emit(
        'update:rowDetail',
        this._.cloneDeep(this.formModel.containerType)
      )
      // 选择否 重置楼层的子容器类型
      if (formItem.name === 'childContainerFlag') {
        this.formModel.containerTypeFloorList.forEach((item) => {
          item.childContainerType = null
          item.childContainerTypeList = null
        })
      }
    },
    setFloors(containerDirection) {
      const directions = containerDirection?.split('') ?? []
      const {
        containerType: { floorNum },
        containerTypeFloorList
      } = this.formModel
      directions.forEach((item) => {
        const dir = item
        const dirFloor = containerTypeFloorList?.find(
          (t) => t.containerDirection === dir
        )
        if (!dirFloor) {
          // 当前面的数量
          let len = containerTypeFloorList.filter(
            (t) => t.containerDirection === dir
          )
          while (floorNum > len) {
            containerTypeFloorList.push({
              ...this.emptyTableFormData,
              containerDirection: dir,
              floorNum: this.geneFloorNum(dir)
            })
            len++
          }
        }
      })
    },
    /**
     * 容器类型 事件处理
     */
    handleContainerType(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel.containerType, formItem.name, val)
      this.$set(
        this.formModel.containerType,
        'containerTypePatternType',
        this.containerTypePatternType
      )
      // 周转箱、料桶  不可放子容器
      if (this.containerTypePatternType === 'turnoverContainer') {
        this.$set(this.formModel.containerType, 'childContainerFlag', 1)
        this.$set(this.formModel, 'childContainerFlag', 1)
      } else {
        this.$set(this.formModel.containerType, 'childContainerFlag', 0)
        this.$set(this.formModel, 'childContainerFlag', 0)
        this.$refs?.floorTableForm?.$children[1]?.clearValidate()
      }
    },
    /**
     * 层 事件处理
     */
    handleFloorNum(val, formItem, changeFormValue, formModel) {
      const oldFloorNum = this.formModel?.containerType?.floorNum ?? 1
      if (oldFloorNum === val) return

      // 设置值
      this.$set(this.formModel.containerType, formItem.name, val)
      // 减
      if (oldFloorNum > val) {
        this.formModel.containerTypeFloorList =
          this.formModel?.containerTypeFloorList?.filter(
            (item) => item.floorNum <= val
          )
        return
      }
      // 加
      const directions =
        this.formModel.containerType?.containerDirection?.split('')
      let diff = Math.abs(val - oldFloorNum)
      while (diff) {
        directions.forEach((item) => {
          const dir = item
          const emptyForm = {
            ...this.emptyTableFormData,
            containerDirection: dir,
            floorNum: this.geneFloorNum(dir)
          }
          this.formModel?.containerTypeFloorList?.push(emptyForm)
        })
        diff--
      }
    },
    /**
     * 生成楼层号
     */
    geneFloorNum(dir) {
      const list =
        this?.formModel?.containerTypeFloorList?.filter(
          (item) => item.containerDirection === dir
        ) ?? []
      return list.length + 1
    },
    prevStep() {
      if (this.addModule === 'container') return
      this.addModule = 'container'
    },
    nextStep() {
      // 先校验
      if (this.addModule === 'goodsPosition') {
        this.$refs.positionTableForm.$children[1]
          .validateTableForm()
          .then((data) => {
            const params = {
              containerType: this.formModel.containerType
            }
            if (this.containerTypePatternType !== 'turnoverContainer') {
              params.containerTypeFloorList =
                this.formModel.containerTypeFloorList
              params.containerTypeGoodsPositionList =
                this.formModel.containerTypeGoodsPositionList
            }
            // 同一面下的货位序号、货位编号 不可重复
            if (
              !this.checkGoodsPosition(params.containerTypeGoodsPositionList)
            ) {
              return
            }
            this.onSubmit(params)
            return
          })
        return
      }
      this.$refs.myForm.getValidateFormModel().then((model) => {
        if (this.isShowFloor) {
          this.$refs.floorTableForm.$children[1]
            .validateTableForm()
            .then((data) => {
              this.stepToNext()
            })
        } else {
          this.stepToNext()
        }
      })
    },
    /**
     * 下一步 货位信息
     * @param {*} mode
     */
    stepToNext() {
      this.formModel.containerTypeFloorList.forEach((item) => {
        item.childContainerType = item.childContainerTypeList?.join(';') ?? ''
      })
      if (this.isShowFloor) {
        this.initGoodsPositionData(this.formModel)
      }
      this.active = 2
      this.addModule = 'goodsPosition'
    },
    /**
     * 校验货位信息
     * 1、货位序号、货位编号不可为空
     * 2、同一面下的货位序号、货位编号 不可重复
     */
    checkGoodsPosition(list = []) {
      const serialNumList = []
      const numList = []
      for (const item of list) {
        if (!item.goodsPositionSerialNum) {
          const dir = this.getDirName(item.containerDirection)
          this.$message.error(
            this.$t('lang.gles.containerType.checkMessage2', [dir])
          )
          return false
        }
        if (!item.goodsPositionNumber) {
          const dir = this.getDirName(item.containerDirection)
          this.$message.error(
            this.$t('lang.gles.containerType.checkMessage3', [dir])
          )
          return false
        }
        // 货位序号重复性校验
        const serialNumKey = `${item.containerDirection}${item.goodsPositionSerialNum}`
        if (serialNumList.includes(serialNumKey)) {
          const dir = this.getDirName(item.containerDirection)
          this.$message.error(
            this.$t('lang.gles.containerType.checkMessage4', [
              dir,
              item.goodsPositionSerialNum
            ])
          )
          return false
        }
        // 货位编号重复性校验
        const numKey = `${item.containerDirection}${item.goodsPositionNumber}`
        if (numList.includes(numKey)) {
          const dir = this.getDirName(item.containerDirection)
          this.$message.error(
            this.$t('lang.gles.containerType.checkMessage5', [
              dir,
              item.goodsPositionNumber
            ])
          )
          return false
        }
        serialNumList.push(serialNumKey)
        numList.push(numKey)
      }
      return true
    },
    /**
     * 获取面的label信息
     */
    getDirName(containerDirection = '') {
      return (
        this.containerDirectionList?.find((t) => t.value === containerDirection)
          ?.label ?? ''
      )
    },
    /**
     * 初始化货位信息
     */
    initGoodsPositionData(model = {}) {
      const { containerTypeFloorList } = this.formModel
      const goodsPositionList = []
      // 楼层信息处理
      containerTypeFloorList.forEach((f, floorIdx) => {
        f.containerTypeCode = model.containerType?.containerTypeCode

        // 生成货位信息
        // 当前层的货位数
        const num = f.rowNum * f.columnNum
        for (let i = 0; i < num; i++) {
          // 不考虑跟之前的数据比较，只要修改全部重新生成
          // const echo = this.getEchoGoodsPosition(f, i)
          const echo = {}
          const gpNum = this.getGoodsPositionNum(echo, f, goodsPositionList)
          const goodsPosition = {
            id: echo.id,
            goodsPositionSerialNum: gpNum,
            goodsPositionNumber: this.getGoodsPositionNumber(echo, f, i),
            containerDirection: f.containerDirection,
            childContainerFlag: model.containerType.childContainerFlag,
            containerTypeCode: model.containerTypecontainerTypeCode,
            childContainerTypeList: f.childContainerTypeList,
            childContainerType: f.childContainerTypeList?.join(';') ?? '',
            floorHigh: f.floorHigh,
            containerMaximum: echo.containerMaximum ?? f.containerMaximum,
            materialMaximum: echo.materialMaximum ?? f.materialMaximum,
            usableFlag: echo.usableFlag ?? 0,
            inStoreOrder: echo.inStoreOrder ?? 100,
            outStoreOrder: echo.outStoreOrder ?? 100,
            containerDirectionName: this.$t(
              this.containerDirectionList?.find(
                (t) => t.value === f.containerDirection
              )?.label ?? ''
            ),
            floorNum: f.floorNum,
            rowNum: this.getGoodsPositionRowNum(i, f.columnNum),
            columnNum: this.getGoodsPositionColumnNum(i, f.columnNum)
          }
          if (this.mode === 'edit') {
            goodsPosition.containerTypeId = model.id
          }
          goodsPositionList.push(goodsPosition)
        }
      })

      this.formModel.containerTypeGoodsPositionList = goodsPositionList
    },
    getEchoGoodsPosition(floor = {}, i) {
      return (
        this.formModel?.containerTypeGoodsPositionList?.find(
          (g) =>
            g.goodsPositionSerialNum === i + 1 &&
            g.containerDirection === floor.containerDirection
        ) ?? {}
      )
    },
    /**
     * 生成货位序号
     */
    getGoodsPositionNum(echo, floor, list = []) {
      if (echo.goodsPositionSerialNum) {
        return echo.goodsPositionSerialNum
      }
      return (
        list.filter(
          (item) => item.containerDirection === floor.containerDirection
        ).length + 1
      )
    },
    /**
     * 获取货位编码
     */
    getGoodsPositionNumber(echo, floor, i) {
      if (echo.goodsPositionNumber) {
        return echo.goodsPositionNumber
      }
      const curCol = this.getGoodsPositionColumnNum(i, floor.columnNum)
      const rowNum = this.getGoodsPositionRowNum(i, floor.columnNum)
      // 机器人组件存在行
      if (
        this.formModel.containerType.containerTypePattern ===
        'containerPatternRobotComponents'
      ) {
        return `${floor.containerDirection}${this.floorNumList[floor.floorNum - 1]}${rowNum}-${curCol}`
      } else {
        return `${floor.containerDirection}${this.floorNumList[floor.floorNum - 1]}${curCol}`
      }
    },
    /**
     * 计算货位所在行
     */
    getGoodsPositionRowNum(i = 0, columnNum = 1) {
      const idx = i + 1
      return !(idx % columnNum)
        ? idx / columnNum
        : Math.floor(idx / columnNum) + 1
    },
    /**
     * 计算货位所在列
     */
    getGoodsPositionColumnNum(i = 0, columnNum = 1) {
      return (i + 1) % columnNum || columnNum
    },
    /**
     * 保存
     */
    async onSubmit(params) {
      this.mode === 'add'
        ? await this.insert(params)
        : await this.update(params)
    },
    /**
     * 新增
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('insertContainerType'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 更新
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateContainerType'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'ContainerTypeList'
      })
    }
  }
}
</script>
<style lang="scss">
.add-ui {
  position: relative;
  padding: 10px;
  .container-type-add-card {
    .el-card__body {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 128px);
      padding: 10px;
      .add-steps {
        width: 60%;
        margin: auto;
      }
      .content-wrapper {
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        .add-module {
          .title {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .footer-btns {
        flex: 0 0 60px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 10px;
        background: #fff;
        text-align: center;
        .btns {
          .el-button {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
