import mapVerify from '@/utils/formValidate'

export const getMoreQueryFormData = (that) => {
  const moreQueryData = [
    // 类型编码
    {
      name: 'containerTypeCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerTypeCode'),
      clearable: true
    },
    // 区域名称
    {
      name: 'containerTypeName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerTypeName'),
      clearable: true
    },
    // 容器形态
    {
      name: 'containerTypePattern',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerTypePattern'),
      clearable: true,
      filterable: true,
      data: that.containerTypePatternList
    },
    // 可放子容器
    {
      name: 'childContainerFlag',
      value: 0,
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.allowSubContainer'),
      clearable: true,
      filterable: true,
      data: that.flagList
    },
    // 外部编码
    {
      name: 'hostCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.hostCode'),
      clearable: true
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 容器编码
    {
      prop: 'containerTypeCode',
      label: that.$t('lang.gles.base.containerTypeCode'),
      isShow: true,
      showOverflowTooltip: true,
      slotName: 'clickSlot',
      sortable: true,
      minWidth: 120
    },
    // 类型名称
    {
      prop: 'containerTypeName',
      label: that.$t('lang.gles.base.containerTypeName'),
      isShow: true,
      showOverflowTooltip: true,
      minWidth: 120
    },
    // 容器形态
    {
      prop: 'containerTypePattern',
      label: that.$t('lang.gles.base.containerTypePattern'),
      isShow: true,
      minWidth: 100,
      formatter(row, column) {
        return that.getDictLabel(that.containerTypePatternList, row[column])
      }
    },
    // 容器形态类型
    {
      prop: 'containerTypePatternType',
      label: that.$t('lang.gles.base.containerTypePatternType'),
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.containerTypePatternTypeList, row[column])
      }
    },
    // 可放子容器
    {
      prop: 'childContainerFlag',
      label: that.$t('lang.gles.base.allowSubContainer'),
      isShow: true,
      formatter(row, column) {
        return !row[column]
          ? that.$t('lang.gles.common.yes')
          : that.$t('lang.gles.common.no')
      }
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      isShow: true,
      showOverflowTooltip: true,
      minWidth: 180
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      isShow: true,
      showOverflowTooltip: true,
      minWidth: 180
    }
  ]

  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const tableFormItem = [
    {
      title: that.$t('lang.gles.common.basicInfo'),
      children: [
        // 类型编码
        {
          name: 'containerTypeCode',
          value: row.containerTypeCode || '',
          prefix: row.containerTypeCode || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.containerTypeCode'),
          rules: mapVerify(['required', 'inputCode'])
        },
        // 类型名称
        {
          name: 'containerTypeName',
          value: row.containerTypeName || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.containerTypeName'),
          rules: mapVerify(['required', 'inputName'])
        },
        // 容器形态
        {
          name: 'containerTypePattern',
          value: row.containerTypePattern || '',
          span: 8,
          component: 'elSelect',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.containerTypePattern'),
          data: that.containerTypePatternList,
          rules: mapVerify(['required']),
          clearable: true,
          disabled: that.mode === 'edit',
          mchange: that.mode !== 'detail' ? that.handleContainerType : ''
        },
        // 容器形态类型
        {
          name: 'containerTypePatternType',
          value: row.containerTypePatternType || '',
          span: 8,
          component: 'elSelect',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.containerTypePatternType'),
          data: that.containerTypePatternTypeList,
          rules: mapVerify(['required']),
          clearable: true,
          disabled: 'disabled'
        },
        // 可放子容器
        {
          name: 'childContainerFlag',
          value: row.childContainerFlag ?? 0,
          span: 8,
          component: 'elSelect',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.allowSubContainer'),
          data: that.flagList ?? [],
          rules: mapVerify(['required']),
          clearable: true,
          disabled: [
            'containerPatternCrate',
            'containerPatternBarrel'
          ].includes(row.containerTypePattern)
        },
        // 外部编码
        {
          name: 'hostCode',
          value: row.hostCode || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.hostCode'),
          rules: mapVerify(['inputLen50'])
        },
        // 长
        {
          name: 'containerTypeLength',
          value: row.containerTypeLength || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: `${that.$t('lang.gles.base.length')}(mm)`,
          rules: mapVerify(['positiveIntegerLen3']),
          clearable: true,
          controls: false
        },
        // 宽
        {
          name: 'containerTypeWidth',
          value: row.containerTypeWidth || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: `${that.$t('lang.gles.base.width')}(mm)`,
          rules: mapVerify(['positiveIntegerLen3']),
          clearable: true,
          controls: false
        },
        // 高
        {
          name: 'containerTypeHigh',
          value: row.containerTypeHigh || '',
          span: 8,
          component: 'elInput',
          adaptionw: true,
          showWordLimit: false,
          label: `${that.$t('lang.gles.base.high')}(mm)`,
          rules: mapVerify(['positiveIntegerLen3']),
          clearable: true,
          controls: false
        },
        // 图片地址
        {
          name: 'imgUrl',
          value: row.imgUrl || '',
          span: 8,
          component: '',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.imgUrl')
        }
      ]
    },
    {
      // 规格信息
      title: that.$t('lang.gles.base.specificationInfo'),
      children: [
        // 面
        {
          name: 'containerDirection',
          value: row.containerDirection || 'F',
          prefix: row.containerDirection || 'F',
          span: 8,
          component: 'elSelect',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.side'),
          data: that.directionList,
          rules: mapVerify(['required']),
          clearable: true,
          filterable: true,
          // disabled: ['托盘', '固定货架', '地堆货架', '周转箱', '料筒'].includes(
          //   row.containerTypePattern
          // ),
          disabled: [
            'containerPatternTray',
            'containerPatternFixedShelf',
            'containerPatternStackingShelves',
            'containerPatternCrate',
            'containerPatternBarrel'
          ].includes(row.containerTypePattern),
          mchange: that.mode !== 'detail' ? that.onContainerFormChange : ''
        },
        // 层
        {
          name: 'floorNum',
          value: row.floorNum || '',
          span: 8,
          component: 'elInputNumber',
          min: 1,
          max: 26,
          controlsPosition: 'right',
          adaptionw: true,
          showWordLimit: false,
          label: that.$t('lang.gles.base.floor'),
          rules: mapVerify(['required']),
          clearable: true,
          // disabled: ['托盘', '周转箱', '料筒'].includes(
          //   row.containerTypePattern
          // ),
          disabled: [
            'containerPatternTray',
            'containerPatternCrate',
            'containerPatternBarrel'
          ].includes(row.containerTypePattern),
          mchange: that.mode !== 'detail' ? that.handleFloorNum : ''
        }
      ]
    }
  ]

  // 给每一项绑定mchange事件
  tableFormItem.forEach((form) => {
    form?.children?.forEach((item) => {
      item.disabled = item.disabled || that.mode === 'detail'
      if (!item.mchange && that.mode !== 'detail') {
        item.mchange = that.onContainerFormChange
      }
    })
  })

  return tableFormItem
}

// 获取表格表单项
export const getTableFormItem = (that, formModel) => {
  const tableFormItem = [
    // 层号
    {
      prop: 'floorNum',
      // component: 'elInputNumber',
      label: that.$t('lang.gles.base.floorNum'),
      controlsPosition: 'right',
      clearable: true,
      controls: false,
      isShow: true,
      width: '60'
    },
    // 行数
    {
      prop: 'rowNum',
      component: 'elInputNumber',
      label: that.$t('lang.gles.base.rowNum'),
      min: 1,
      max: 100,
      controlsPosition: 'right',
      isShow:
        formModel.containerTypePattern === 'containerPatternRobotComponents',
      clearable: true,
      width: '160'
    },
    // 列数
    {
      prop: 'columnNum',
      label: that.$t('lang.gles.base.columnNum'),
      component: 'elInputNumber',
      min: 1,
      max: 100,
      controlsPosition: 'right',
      isShow: true,
      clearable: true,
      width: '160'
    },
    // 层高
    {
      prop: 'floorHigh',
      label: `${that.$t('lang.gles.base.floorHigh')}(cm)`,
      component: 'elInput',
      isShow: true,
      controls: false,
      placeholder: '请输入',
      rules: mapVerify(['positiveIntegerLen3'])
    },
    {
      prop: 'childContainerTypeList',
      component: 'elSelect',
      label: that.$t('lang.gles.base.allowSubContainer​Type'),
      clearable: true,
      filterable: true,
      data: that.childContainerTypeList ?? [],
      disabled: !!formModel.childContainerFlag,
      multiple: true,
      collapseTags: true,
      isShow: true,
      width: '300'
    },
    // 货位可放物料数量上限
    {
      prop: 'materialMaximum',
      label: that.$t('lang.gles.base.materialMaximumOfPosition'),
      component: 'elInputNumber',
      min: 1,
      max: 100,
      controlsPosition: 'right',
      isShow: true,
      clearable: true
    },
    // 货位可放容器数量上限
    {
      prop: 'containerMaximum',
      label: that.$t('lang.gles.base.containerMaximumOfPosition'),
      component: 'elInputNumber',
      min: 1,
      max: 100,
      controlsPosition: 'right',
      isShow: true,
      clearable: true
    }
  ]
  if (that.mode === 'detail') {
    tableFormItem.forEach((item) => {
      item.disabled = true
    })
  }
  // return tableFormItem.filter((item) => item.isShow)
  return tableFormItem
}
// 获取货位表单项
export const getGoodsPositionFormItem = (that, row) => {
  const tableFormItem = [
    // 货位序号
    {
      prop: 'goodsPositionSerialNum',
      component: 'elInputNumber',
      label: that.$t('lang.gles.base.goodsPositionNum'),
      isShow: true,
      min: 1,
      minWidth: 120,
      controlsPosition: 'right',
      rules: mapVerify(['required'])
    },
    // 货位编号
    {
      prop: 'goodsPositionNumber',
      label: that.$t('lang.gles.base.goodsPositionNumber'),
      component: 'elInput',
      isShow: true,
      clearable: true,
      minWidth: 120,
      rules: mapVerify(['required'])
    },
    // 可放子容器
    {
      prop: 'childContainerFlag',
      label: that.$t('lang.gles.base.allowSubContainer'),
      component: 'elSelect',
      data: that.flagList,
      isShow: true,
      clearable: true,
      disabled: true,
      minWidth: 120
    },
    {
      prop: 'childContainerTypeList',
      component: 'elSelect',
      label: that.$t('lang.gles.base.allowSubContainer​Type'),
      data: that.childContainerTypeList,
      isShow: true,
      disabled: true,
      multiple: true,
      collapseTags: true,
      width: 240
    },
    // 货位可放物料数量上限
    {
      prop: 'materialMaximum',
      label: that.$t('lang.gles.base.materialMaximumOfPosition'),
      component: 'elInputNumber',
      min: 1,
      controlsPosition: 'right',
      isShow: true,
      disabled: true,
      minWidth: 180
    },
    // 货位可放容器数量上限
    {
      prop: 'containerMaximum',
      label: that.$t('lang.gles.base.containerMaximumOfPosition'),
      component: 'elInputNumber',
      min: 1,
      controlsPosition: 'right',
      isShow: true,
      disabled: true,
      minWidth: 180
    },
    // 入库顺序
    {
      prop: 'inStoreOrder',
      label: that.$t('lang.gles.base.inStoreOrder'),
      component: 'elInputNumber',
      min: 1,
      controlsPosition: 'right',
      isShow: true,
      clearable: true,
      minWidth: 180,
      // max: 99999999,
      rules: mapVerify(['positiveIntegerLen8'])
    },
    // 出库顺序
    {
      prop: 'outStoreOrder',
      label: that.$t('lang.gles.base.outStoreOrder'),
      component: 'elInputNumber',
      min: 1,
      // max: 99999999,
      controlsPosition: 'right',
      isShow: true,
      clearable: true,
      minWidth: 180,
      rules: mapVerify(['positiveIntegerLen8'])
    },
    // 启用状态
    {
      prop: 'usableFlag',
      label: that.$t('lang.gles.base.usableFlag'),
      component: 'elSwitch',
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      activeValue: 0,
      inactiveValue: 1,
      isShow: true,
      class: 'switch'
    },
    // 面
    {
      prop: 'containerDirectionName',
      label: that.$t('lang.gles.base.side'),
      isShow: true,
      width: 60
    },
    // 层
    {
      prop: 'floorNum',
      label: that.$t('lang.gles.base.floor'),
      isShow: true,
      width: 60
    },
    // 行
    {
      prop: 'rowNum',
      label: that.$t('lang.gles.base.row'),
      isShow: true,
      width: 60
    },
    // 列
    {
      prop: 'columnNum',
      label: that.$t('lang.gles.base.column'),
      isShow: true,
      width: 60
    }
  ]
  tableFormItem.forEach((item) => {
    if (that.mode === 'detail') {
      item.disabled = true
    }
  })
  return tableFormItem
}
